import * as React from 'react';
import { Link } from 'react-router-dom';


import { Button, Typography, Box, AppBar, Toolbar } from '@mui/material';

import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { grey } from '@mui/material/colors';

import {  alphaBackgroundColor50 } from '../constants/Colors';


const ButtonLink = styled((props) => (
  <Button component={Link} disableTouchRipple disableFocusRipple {...props} />))(() => ({
    color: grey,
    fontSize: '1.25rem',
  })
);

const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  
  [theme.breakpoints.up('xl')]: {
    marginLeft: theme.spacing(24),
    marginRight: theme.spacing(24),
  },
  [theme.breakpoints.down('xl')]: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));


const navTabs = [
  {name: 'Home', link: '/'},
  {name: 'Library', link: '/library'},
  {name: 'Gallery', link: '/gallery'},
];

export default function Header() {
  return (
    <Box width="100%" position="absolute">
      <CssBaseline />
      <AppBar  display='flex' elevation={0} sx={{ bgcolor:alphaBackgroundColor50 }}>
        <ToolbarStyled disableGutters={false}>
          <Box  flexDirection='row' display='flex' >
            <Box alignSelf='center' display={{sm:'none', xs: 'none', md: 'flex'}}>
              <Typography
                variant="button"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: '1.25rem'
                }}
              >
                LARA LOGAN
              </Typography>
            </Box>

            <Box  flexDirection='row' display='flex'>
              {navTabs.map((route, index) => {
                let sx = {}
                if (window.location.pathname === route.link ) {
                  sx = {textDecoration: 'underline', textDecorationColor: 'white', textUnderlineOffset: '.5rem'};
                }

                return (
                  <ButtonLink disabled={route.name == 'Gallery' ? false : false} variant='text' key={`${index}`} to={route.link} sx={{...sx, color: '#F8F9FA', fontSize:"1.25rem", fontFamily: 'Oswald,Roboto,'}} >
                    {route.name}
                  </ButtonLink>
                );
              })}
            </Box>
          </Box>
          </ToolbarStyled>
        </AppBar>
      </Box>
    );
  }
