import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
      // mode: 'light',
      mode: 'dark',
      // primary: {
        // main: grey[500],
        // main: blueGrey[800],
       
      // },
      background: {
        // default: alpha('#836223', .60)
      },
      // secondary: {
        // main: red[500],
      // }

    },
    typography:{
      fontSize: 14,
      fontFamily: "Oswald, Roboto",
    },
    // shadows: {
      // [0.5]: "0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 2px 0px rgba(0,0,0,0.12)",
    // },
    spacing: 8,
    components: {
      // Name of the component
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          // disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&:hover': {
              // boxShadow: '0px 4px 35px -5px #4082f5;',
            },
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            // textDecoration: "none",
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "transparent",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "transparent",
          },
        },
      },
    },
  });
  
  // should be elevation 25
  // theme.shadows.push("0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 2px 0px rgba(0,0,0,0.12)");
  
  theme = responsiveFontSizes(theme)
  
  export default theme;