import React from 'react';

import { useMediaQuery, Stack, Typography, Container, ImageList, ImageListItem, Box, Fade } from '@mui/material';

import Layout from '../components/Layout';
import { GoToTop, ScrollTop } from '../components/CustomComponents';

import lara_bio from '../assets/lara_bio.webp';
const img_url = lara_bio;


export default function Gallery() {
  return(
    <Layout>
      <Container maxWidth='xl' sx={{mt: 10}}>
        <Stack  direction='column' alignItems='center' justifyContent='center' spacing={4}>
          <Typography variant='h2' >Gallery</Typography>
          <MasonryImageList />
        </Stack>
      </Container>
      <GoToTop />
      <ScrollTop />
    </Layout>
  );
}

const MasonryImageList = () => {
  const itemData = [...Array(24).keys()];
  let colCount = 4
  const betweenMdLg = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
  const betweenSmMd = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  const lessSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  if (betweenMdLg) {
    colCount = 3;
  } else if (betweenSmMd) {
    colCount = 2;
  } else if (lessSM) {
    colCount = 1
  }
  
  return (
    <Fade in timeout={800}>
      <Box>
        <ImageList cols={colCount} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item}>
              <img
                src={img_url}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Fade>
  );
}