/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { Box, Slide } from '@mui/material';

import Divider from '@mui/material/Divider';

import Header from './Header';
import Footer from './Footer';

// React Hooks
import useScrollTrigger from '@mui/material/useScrollTrigger';



function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <Box>
        {children}
      </Box>
    </Slide>
  );
}

export default function Layout ({
  children
}) {

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <HideOnScroll><Header /></HideOnScroll>
      <Box component='main'  display='flex' flexDirection='column'>
        {children}
      </Box>
      <Divider sx={{margin: '2rem 4rem', borderBottomWidth: 'medium'}}/>
      <Footer />
    </Box>
  );

}