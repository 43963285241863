import * as React from 'react';
import { styled } from '@mui/material/styles';

import { Box, Button, Typography, Avatar, Stack, Input } from '@mui/material';

import { CopyrightRounded } from '@mui/icons-material';


import ts_logo from '../assets/ts_icon.png';
import rumble_logo from '../assets/rumble.png';
import locals_logo from '../assets/locals.webp';
import twitter_logo from '../assets/twitter.webp';



const Follow = () => (
  <Stack direction='column'  spacing={2} maxWidth='380px'>
    <Typography variant='h4' fontFamily='Prata, Roboto' textTransform="uppercase" alignSelf='center'>Follow On</Typography>
    <Stack direction='row' justifyContent='space-evenly' spacing={5}>
      <Box alignSelf='center'><a href="https://truthsocial.com/@laralogan" target="_blank" rel="noopener noreferrer"><Avatar src={ts_logo} sx={{ height: "50px", width: "50px", filter: "grayscale(1)" }}/></a></Box>
      <Box alignSelf='center'><a href="https://rumble.com/c/LaraLogan" target="_blank" rel="noopener noreferrer"><Avatar src={rumble_logo} sx={{ height: "50px", width: "50px", filter: "grayscale(1)" }}/></a></Box>
      <Box alignSelf='center'><a href="https://laralogan.locals.com" target="_blank" rel="noopener noreferrer"><Avatar src={locals_logo} sx={{ height: "50px", width: "50px", filter: "grayscale(1)" }}/></a></Box>
      <Box alignSelf='center'><a href="https://twitter.com/laralogan" target="_blank" rel="noopener noreferrer"><Avatar src={twitter_logo} sx={{ height: "50px", width: "50px", filter: "grayscale(1)" }}/></a></Box>
    </Stack>
  </Stack>
);
const Subscribe = () => (
  <Stack direction='column' minWidth='20rem' maxWidth='25rem' spacing={1}>
    <Typography variant='h4' fontFamily='Prata, Roboto' textTransform="uppercase">Join Our Newsletter</Typography>
    <Input placeholder='Email address'></Input>
    <Button variant='contained' sx={{backgroundColor: '#2D71FB'}}>Subscribe</Button>
  </Stack>
);

const CopyRight = () => (
  <Box display="flex" flexDirection='row' alignItems="center" mb={3}><CopyrightRounded fontSize='small'/><Typography variant='body1' ml={1} >Copyright {new Date().getFullYear()} • Lara Logan</Typography></Box>
);

const FootBoxStyled = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(16),
    marginRight: theme.spacing(16),
    justifyContent: "space-evenly",
    flexDirection: 'row',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Footer() {
  return (
    <Box display='flex' flexDirection='column' my={2}>
      <FootBoxStyled  component='footer'>
        <Follow />
      </FootBoxStyled>
      <Box alignSelf='center' mt={2}>
        <CopyRight />
      </Box>
    </Box>
  );
}