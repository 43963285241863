import React from 'react';
import { Link  } from 'react-router-dom';

import { Box, Typography, Button } from '@mui/material';

import Layout from '../components/Layout';

import { alphaBackgroundColor } from '../constants/Colors';


export const NotFound = () => (
  <Box bgcolor={alphaBackgroundColor} height='90vh' width='100vw' display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
    <Typography fontSize='6vh' fontFamily='Oswald, Roboto'  sx={{  }} >Page Doesn't Exist</Typography>
    <Button component={Link} variant='outlined' to='/' sx={{ color: 'white', fontSize: '1.25rem', mt: 3}}>Return Home</Button>
  </Box>
)

export default function Page404(){
  return (
    <Layout>
      <NotFound />
    </Layout>
  );
}