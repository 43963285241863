import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from '../ui/Home';
import Library from '../ui/Library';
import Gallery from '../ui/Gallery';
import Page404 from '../ui/Page404';
import VideoPage from '../ui/VideoPage';


const Router =()=> (
  <BrowserRouter>
    <Routes>
      <Route path="/" element= {<Home />}></Route>
      <Route path="/watch/:videoGuid" element= {<VideoPage />}></Route>
      <Route path="/library" element= {<Library />}></Route>
      <Route path="/gallery" element= {<Gallery />}></Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  </BrowserRouter> 
)
  
  export default Router;