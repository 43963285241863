import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import GlobalStyles from '@mui/material/GlobalStyles';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from './App';
import theme from './theme';

const inputGlobalStyles = <GlobalStyles styles={{
  html: {
    height: '100vh',
    width: '100vw',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}} />;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    {inputGlobalStyles}
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);
