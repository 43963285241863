
import React from 'react';
import { useDispatch  } from 'react-redux'

import { loadAllContent } from './redux/slices/contentSlice';

import Box from '@mui/material/Box';

import Router from './routes/Router';

const App = () => {
  const dispatch = useDispatch();
  dispatch(loadAllContent());
  return (
    <Box className="App" sx={{ height: '100%' }}>
      <Box> 
        <Router/>
      </Box>
    </Box>
  );
}

export default App;
