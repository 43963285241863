import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { TextField, Box, Zoom, Fab, Stack, Fade, Typography } from "@mui/material";

import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import useScrollTrigger from "@mui/material/useScrollTrigger";

export const GoToTop = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  
  return null;
}

export const TextFieldStyled = styled((props) => <TextField {...props} />)(({ theme }) => ({
  "& label": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      borderWidth: "1px",
    },
  },
}));

export const ScrollTop = () => {
  const trigger = useScrollTrigger({threshold: 100});

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  return (
    <Zoom in={trigger}>
      <Box
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
          zIndex: 1,
          // display: { xl: 'initial', lg: 'initial', md: 'initial', sm: 'none', xs: 'none'}
        }}
      >
        <Fab
          disableFocusRipple
          disableTouchRipple
          onClick={scrollToTop}
          sx={{color: 'gray'}}
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Zoom>
  )
}

const ValidationIcon = ({isValid}) => (
  isValid ? <CheckCircleOutlineIcon sx={{color: "lightgreen"}} fontSize="small" /> :  <ErrorOutlineIcon color="error" fontSize="small" />
);

export const FeedBack = ({passed, message, ...props}) => {
  return (
    <Fade in><Box>
      <Stack spacing={.5} direction="row" alignItems="center" {...props}>
        <ValidationIcon isValid={passed} /><Typography variant="body1">{message}</Typography>
      </Stack>
    </Box></Fade>
  );
}
