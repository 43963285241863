import { useState, useEffect } from "react";

const OPTIONS = {
  root: null,
//   rootMargin: "0px",
  threshold: 1,
};

export function useIsVisible(elementRef) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    
    if (elementRef.current) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('set vis');
            setIsVisible(true);
            observer.unobserve(elementRef.current);
          }
        });
      }, OPTIONS);
      observer.observe(elementRef.current);
    }
  }, [elementRef]);

  return isVisible;
};

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              setState(entry.isIntersecting);
          }, { rootMargin }
      );

      element.current && observer.observe(element.current);

      return () => observer.unobserve(element.current);
  });

  return isVisible;
};

export function isNullOrEmpty(value) {
  if (value) {
    return false;
  }
  return true;
}

export function isEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}