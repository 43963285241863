import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';

import { useMediaQuery, Fade, Typography, TextField, Button, Container, Stack, CardMedia, Box } from '@mui/material';

import { styled } from '@mui/material/styles';

import { createUser } from '../redux/slices/userSlice';

import lara_logan_crop from '../assets/lara_logan_crop.jpeg'
import l_l_1 from '../assets/l_l_1.png';

import { isNullOrEmpty, isEmail } from '../services/helper';

import Layout from '../components/Layout';
import { ScrollTop, FeedBack, GoToTop } from '../components/CustomComponents';
import CardGrid from '../components/CardGrid';




const TextHeader = ({ text }) => (
  <Box sx={{position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)',}}>
    <Box display='flex' flexDirection='column'>
    <Fade in timeout={800}><Typography variant='body1' sx={{
        color: 'white', fontSize:"8vh", fontWeight: '900', lineHeight: 1.2, textAlign: "center", textShadow: '-4px 4px 0 #000', fontFamily: 'Oswald,Prata,Roboto' 
      }}>
        {text}
      </Typography></Fade>
      <Fade in timeout={2000}><Typography variant='body1' sx={{
        color: 'white', fontSize:"3.5vh", fontWeight: '900', lineHeight: 1.2, textAlign: "center", textShadow: '-4px 4px 0 #000', fontFamily: 'Oswald,Prata,Roboto', mt:'.5rem'
      }}>
        The Rest of the Story
      </Typography></Fade>
      <Fade in timeout={2600}><Typography variant='body1' sx={{
        color: 'white', fontSize:"3vh",  lineHeight: 1.2, textAlign: "center", textShadow: '-4px 4px 0 #000', fontFamily: 'Oswald,Prata,Roboto', mt: '2rem'
      }}>
        Gracie Award • John Aubuchon Press Freedom Award • David Kaplan Award
      </Typography></Fade>
      <Fade in timeout={3000}><Typography variant='body1' sx={{
        color: 'white', fontSize:"3vh",  lineHeight: 1.2, textAlign: "center", textShadow: '-4px 4px 0 #000', fontFamily: 'Oswald,Prata,Roboto', mt: '1rem'
      }}>
        Six Continents
      </Typography></Fade>
    </Box>
  </Box>
);


const StaticBackground = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Fade in timeout={800}>
      <Box>
        <Box height={ matches ? "37vh" :  "37vw"} sx={{float: 'right'}}>
          <CardMedia
            component="img"
            height='100%'
            image={l_l_1}
            alt="Lara Logan Photo"
          />
        </Box>
        <Box sx={{ width: '100vw', height: '90vh' }}>
          <TextHeader text="Lara Logan"/>
        </Box>
      </Box>
    </Fade>
  )
}


export default function Home(){
  return (
    <Layout>
      <StaticBackground />
      <Container maxWidth='xl'>
        <Stack  direction='column' alignItems='center' justifyContent='center' spacing={{ xl: 30, lg: 30, md: 30, sm:20, xs: 10 }} mx={2}>
          <Fade in timeout={600}><Box><NewSeries /></Box></Fade>
          <Fade in timeout={1200}><Box ><BecomeAMember /></Box></Fade>
          <Fade in timeout={600}><Box><NewReleases /></Box></Fade>
        </Stack>
      </Container>
      <ScrollTop />
      <GoToTop />
    </Layout>);
}

const NewSeries = () => {
  const vidUrl = "https://iframe.mediadelivery.net/embed/82994/fe42548b-102a-4eef-b8bd-cdfe270f9273";
  return (
    <Stack direction='column' alignItems='center' justifyContent='center' spacing={5.5} width={1}>
        <Box display='flex' flexDirection='row' mt={1} mb={1.5}>
          <Typography fontSize='3rem' fontFamily='Oswald, Roboto' color='#F8F9FA'>Press Reel</Typography>
        </Box>
        <Box width={{ xl:'1536px', lg:'1200px', md: '96vw', sm:'96vw', xs:'96vw' }} height={{ xl:'70vh', lg:'70vh', md: '70vh', sm:'50vh', xs:'60vh' }}>
          <CardMedia src={`${vidUrl}`}component="iframe" allow="accelerometer; gyroscope; encrypted-media;" allowFullScreen={true} loading='lazy' sx={{border: "none", height: '100%'}}/>
        </Box>
    </Stack>
  );
}

const TextFieldStyled = styled((props) => (
  <TextField  InputProps={{sx: {fontSize: '1.25rem'}}} InputLabelProps={{sx: {fontSize: '1.25rem'}}} {...props} />
))(({ theme }) => ({
  '& label' : {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'none',
  },
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      borderWidth: '1px'
    },
  },
}));



const BecomeAMember = () => {
  const matchesMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });

  const missionText = [
    'Ad proident ut aliquip in aliqua dolore ea est reprehenderit velit. id nostrud nisi nulla.',
    'Ex excepteur nulla ipsum elit non voluptate cupidatat excepteur et id id nostrud nisi nulla. Ad proident ut aliquip in aliqua dolore ea est reprehenderit velit.',
    'Ex excepteur nulla ipsum elit non voluptate cupidatat excepteur et id id nostrud nisi nulla.',
  ];
  
  useEffect(() => {
    if(isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || isNullOrEmpty(email) || !isEmail(email) )  {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  },[firstName, lastName, email]);

  const registerClick = async () => {
    const payload = {
      first_name: firstName.replace(/^\s+|\s+$/gm,''),
      last_name: lastName.replace(/^\s+|\s+$/gm,''),
      email: email.replace(/^\s+|\s+$/gm,'')
    }
    try {
      setFeedback({ passed: null, comp: null });
      setDisabled(true);
      await dispatch(createUser(JSON.stringify(payload))).unwrap();
      setFeedback({ passed: false, comp: <FeedBack passed={true} message="Subscribed!" mt={1} /> });
      setDisabled(false);
    } catch (exception) {
      let msg = "Registeration failed. Please try again";
      if (exception.detail == "email already registered") {
        msg = "Your email is already registered!";
      }
      setFeedback({ passed: false, comp: <FeedBack passed={false} message={msg} mt={1} /> });
      setDisabled(false);
    }
    
  }

  return (    
    <Box backgroundColor='#000000' width='100vw' display='flex' flexDirection='row' justifyContent="center" pt={4} pb={14}>
      <Container maxWidth='xl'>
        <Stack direction={ matchesMd ? 'column-reverse' : 'row'} alignItems='center' justifyContent='center' spacing={6}>
          <Box width='100%' position='relative'>
            <Box sx={{
              minHeight: '40vh',
              backgroundImage: `url(${lara_logan_crop})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: { xl: '70% 100%', lg: '70% 100%', md: '100% 100%',sm: '100% 100%',xs: '100% 100%',},
              filter: "grayscale(1)",
              py:2
            }}>
              <Stack spacing={5}>
                <Box display='flex' flexDirection='row' mt={1} mb={1.5}>
                  <Typography fontSize='3rem' fontFamily='Oswald, Roboto' color='#F8F9FA'>Support our mission</Typography>
                </Box>
                  {missionText.map((i, idx) => (
                    <Box key={idx}><Typography variant='h5' fontSize='3vh'>{i}</Typography></Box>
                  ))}
              </Stack>
            </Box>
            <Button variant="contained" size='large' sx={{ position: 'absolute', alignSelf:"center", backgroundColor: '#4BB7A8', textTransform: 'none', fontSize: '1.5rem', mt: 2 }} onClick={() => window.open('https://www.givesendgo.com', '_blank')}>GiveSendGo</Button>
          </Box>
          
          <Box width='100%' mt={2}>
            <Box display='flex' flexDirection='column' justifyContent='center'>
              <Typography fontSize='3.2rem' fontWeight="700" fontFamily='Oswald, Roboto' alignSelf='end' mx={2} mb={3}>Stay Connected</Typography>
            </Box>
            <Stack spacing={3}>
              <TextFieldStyled id="firstname-field" required label="First name" variant="outlined" fullWidth onChange={(e) => { setFirstName(e.target.value) }} />
              <TextFieldStyled id="lastnamename-field" required label="Last name" variant="outlined" fullWidth onChange={(e) => { setLastName(e.target.value) }}/>
              <TextFieldStyled id="email-field" required label="E-mail" variant="outlined" fullWidth sx={{mb: '1rem'}} onChange={(e) => { setEmail(e.target.value) }} />
              <Button variant="contained" size='large' disabled={isDisabled} sx={{backgroundColor: '#33A1FD', fontSize:'1.25rem'}} onClick={() => { registerClick() }}> Register</Button>
              {feedBack.passed !== null && feedBack.comp}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
    );
}


const NewReleases = () => {
  const newRelease = useSelector((state) => state.content.new_release);
  return (
    <>
      <CardGrid title='Newly Added' cards={newRelease} />
    </>
  );
}