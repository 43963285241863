import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useParams } from "react-router-dom";

import { styled } from '@mui/material/styles';
import { useMediaQuery, Fade, Typography, TextField, Button, Container, Stack, Box, CardMedia } from '@mui/material';

import Layout from '../components/Layout';

import { getVideoByGuid, getVideoUrl } from '../redux/slices/contentSlice';

import { GoToTop } from '../components/CustomComponents';


import CardsGrid from '../components/CardGrid';


const VideoPlayer = () => {
  const [vidUrl, setVidUrl] = useState(null);
  const dispatch = useDispatch();
  const { videoGuid } = useParams();
  const selectedVideo = useSelector((state) => getVideoByGuid(state, videoGuid));

  const getUrl = async () => {
    try {
      const vidUrl = await dispatch(getVideoUrl(selectedVideo.id)).unwrap();
      setVidUrl(vidUrl);
    } catch (exception) {

    }
  }
  useEffect(() => {
    if(selectedVideo)  {
      getUrl();
    }
  },[selectedVideo]);

  if (selectedVideo) {
    return (
      <Box width={1} alignItems='center' display='flex' flexDirection='column'>
        <CardMedia
          component="iframe"
          sx={{ height: { xs: "60vh", md: "80vh", lg: "80vh", xl: "80vh"}, border: "none", mt: 10, width:'96vw' }}
          src={`${vidUrl}`}
          allow="accelerometer; gyroscope; autoplay; encrypted-media;"
          allowFullScreen={true}
        />
        <Container maxWidth='xl' disableGutters>
          <Typography fontSize='2.5rem' alignSelf='flex-start' mx={{xs: 2, sm: 2, md: 2, lg: 2, xl: 0 }}>{selectedVideo.title}</Typography>
        </Container>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default function VideoPage(){
  const newRelease = useSelector((state) => state.content.new_release);
  
  return (
    <Layout>
      <VideoPlayer />
      <Stack direction='column' alignItems='center' justifyContent='center' mt={5}>
        <Box mx={2} maxWidth='xl'>
          <CardsGrid title='Similar Titles' cards={newRelease} />
        </Box>
      </Stack>
      <GoToTop />
    </Layout>
  );
}
