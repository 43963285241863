import React from 'react';
import {
  Box,
  Chip,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  Grow,
  ImageListItemBar,
  ImageListItem
} from "@mui/material";
import { useNavigate  } from 'react-router-dom';
import TimerIcon from "@mui/icons-material/Timer";


const HybrideTile = ({source, title, time }) => (
  <ImageListItem>
    <CardMedia component="div" image={source} alt={title} sx={{ pt: "56.25%" }}/>
    <ImageListItemBar sx={{bgcolor:'transparent'}} position="bottom" subtitle={ <Box display='flex' sx={{justifyContent:'flex-end', }}><Chip label={time} variant="outlined" size='small' icon={<TimerIcon fontSize='small' />} sx={{ bgcolor: 'black', fontSize: '1rem' }} /></Box>} ></ImageListItemBar>
  </ImageListItem>
);

export default function CardsGrid({ title, cards }) {
  const navigate = useNavigate();

  return (
    <>
    <Box display='flex' flexDirection='column' justifyContent='center' mb={2}>
        <Typography fontSize={{ xl: '3.25rem', lg: '3.25rem', md: '3.25rem', sm: '2.75rem', xs: '2.75rem'}} fontFamily='Oswald, Roboto' alignSelf='left'>{title}</Typography>
      </Box>
    <Grid container spacing={4}>
      {cards.map((card, idx) => (
        <Grid item key={idx} xs={12} sm={6} md={4} lg={3}>
          <Grow in>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "8px",
              }}
            >
              <CardActionArea onClick={() => navigate(`/watch/${card.vid_guid}`)}>
                  <HybrideTile  source={card.thumbnail_url} title={card.title} time={card.vid_length}/>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h5">
                      {card.title}
                    </Typography>
                  </Box>
                </CardContent>
                </CardActionArea>
            </Card>
          </Grow>
        </Grid>
      ))}
    </Grid>
    </>
  );
}
