import React, { useState } from 'react';
import _ from 'underscore';

import { useSelector } from 'react-redux';
import { useNavigate  } from 'react-router-dom';


import { Box, Fade, Typography, Divider, Button, Container, Chip, Stack } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { alphaBackgroundColor, alphaBackgroundColor30 } from '../constants/Colors';

import Layout from '../components/Layout';
import { GoToTop, ScrollTop } from '../components/CustomComponents';
import CardGrid from '../components/CardGrid';

const SIXY_MINUTES = '60_min';
const INTERVIEW = 'interview';
const NEW_RELEASES = 'new_releases';
const SEGMENTS = 'segment';

const ani = 'https://vz-e9a69027-7d0.b-cdn.net/fe42548b-102a-4eef-b8bd-cdfe270f9273/preview.webp';

const StaticBackground = () => {
  return (
    <Fade in timeout={800}>
      <Box sx={{ width: '100vw', height: '90vh', backgroundImage: `url(${ani})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%'}}>
        <Box id='ns-box' display='flex' flexDirection='column' justifyContent='flex-end'  height='100%' width='100%' sx={{ bgcolor: alphaBackgroundColor30 }} >
          <Box mb={{xl:'10rem', lg: '10rem'}} ml={{xl: 8, lg: 8, md: 6, sm: 2, xs: 2 }} ><NewSeriesDescription /></Box>
        </Box>
      </Box>
    </Fade>
  )
}

const missionText = [
  'Ex excepteur nulla ipsum elit non voluptate cupidatat excepteur et id id nostrud nisi nulla.',
  'Trailer now available.',
];

const NewSeriesDescription = () => {
  const navigate = useNavigate();
  
  return (    
    <Box>
      <Box  display='flex' flexDirection="column" maxWidth='960px'>
        <Box display='flex' flexDirection='column'>
          <Typography fontSize='1.25rem' fontFamily='Oswald, Roboto' color='#F8F9FA'>Releasing 2023</Typography> 
          <Typography fontSize='5vh' fontWeight="700" fontFamily='Oswald, Roboto' >Project: Combat Trafficking</Typography>
        </Box>
        <Box>
          {missionText.map((i, idx) => (
            <Typography key={idx} paragraph fontSize='2vh'>{i}</Typography>
          ))}
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography fontSize='1.25rem' fontFamily='Oswald, Roboto' color='#F8F9FA' mr={1}>Hosted by</Typography>
          <Typography fontSize='1.25rem' fontFamily='Oswald, Roboto' color='#33A1FD'>Lara Logan</Typography>
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography fontSize='1.25rem' fontFamily='Oswald, Roboto' color='#F8F9FA' mr={1}>Produced by</Typography>
          <Typography fontSize='1.25rem' fontFamily='Oswald, Roboto' color='#33A1FD'>Lara Logan</Typography>
        </Box>
        <Box mt={2}>
          <Button variant='outlined' onClick={() => navigate('/watch/fe42548b-102a-4eef-b8bd-cdfe270f9273')} sx={{color: 'white', border: '2px solid white', ':hover':{ borderStyle: 'solid', borderWidth: '2px'} }}>Watch Now</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default function Library(){
  const newRelease = useSelector((state) => state.content.new_release);
  const videos = useSelector((state) => state.content.videos);
  const sixtyMinute = _.filter(videos, (i) => (i.vid_type === SIXY_MINUTES));
  const interviews = _.filter(videos, (i) => (i.vid_type === INTERVIEW));
  const segments = _.filter(videos, (i) => (i.vid_type === SEGMENTS));
  const [filters, setFilters] = useState(videoTypes);
  
  return (
    <Layout>
      <Box display='flex' flexDirection='column'>
        <Box>
          <StaticBackground />
        </Box>
        <Container maxWidth='xl'>
          <Stack display='flex' alignItems='flex-start' justifyContent='center' alignSelf='center' direction='column' backgroundColor={alphaBackgroundColor}  spacing={5} mx={0} mt={5} >
            <Box width={1}>
              <Typography fontSize='2rem' fontFamily='Oswald, Roboto' alignSelf='left' mb={2}>Video Library</Typography>
              <Filters filters={filters} setFilters={setFilters} />
            </Box>
            {filters[NEW_RELEASES].active ? <><NewReleases videos={newRelease} /> <Divider flexItem sx={{margin: '8rem 4rem', borderBottomWidth: 'light'}}/></> : null }
            {filters[SIXY_MINUTES].active ? <><SixtyMinutes videos={sixtyMinute} /><Divider flexItem sx={{margin: '8rem 4rem', borderBottomWidth: 'light'}}/></> : null }
            {filters[INTERVIEW].active ? <><Interviews videos={interviews} /><Divider flexItem sx={{margin: '8rem 4rem', borderBottomWidth: 'light'}}/></> : null }
            {filters[SEGMENTS].active ? <Segments videos={segments} /> : null }
          </Stack>
        </Container>
      </Box>
      <GoToTop />
      <ScrollTop />
    </Layout>
  );
}


const videoTypes ={
  [NEW_RELEASES]: {label:'Newly Added', active: true },
  [SIXY_MINUTES]: {label:'60 Minutes', active: true },
  [INTERVIEW]: {label:'Interviews', active: true },
  [SEGMENTS]: {label:'Segments', active: true },
}

const Filters = ({ filters, setFilters }) => {
  let currentFilters = {...filters};
  const handleClick = (filter) => {
    currentFilters[filter].active = !currentFilters[filter].active;
    setFilters(currentFilters);
  };

  return (
    <Box display="flex" direction="row" flexWrap='wrap' gap={2}>
      {_.map(filters, (i, idx) => {
        return (<Chip
          key={i.label}
          label={i.label}
          color='primary'
          onClick={() =>handleClick(idx)}
          onDelete={() =>handleClick(idx)}
          deleteIcon={i.active ? null : <AddIcon />}
          variant={i.active ? "filled" : "outlined"}
          sx={{fontSize: '1.25rem', p: {xl:2, lg:2, md:2, sm:1,xs:1} }}
        />);
      })}
    </Box>
  );
}


const NewReleases = ({ videos }) => {
  return (
    <Fade in timeout={800}><Box width={1}><CardGrid title="Newly Added" cards={videos} /></Box></Fade>
  );
}

const SixtyMinutes = ({videos}) => {
  return (
    <Fade in timeout={800}><Box width={1}><CardGrid title="Sixty Minutes" cards={videos} /></Box></Fade>
  );
}

const Interviews = ({ videos }) => {
  return (
    <Fade in timeout={800}><Box width={1}><CardGrid title="Interviews" cards={videos} /></Box></Fade>
  );
}

const Segments = ({ videos }) => {
  return (
    <Fade in timeout={800}><Box width={1}><CardGrid title="Segments" cards={videos} /></Box></Fade>
  );
}