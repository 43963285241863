import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../../services/client';


const initialState = {
  first_name: '',
  last_name: '',
  email: '',
}

let config = {
    headers: {
        "Content-Type": "application/json"
    }
  }


export const createUser = createAsyncThunk(
    'users/createUser',
    async (userInfo, { rejectWithValue }) => {
      try{
        const response = await axios.post(BASE_URL + '/auth/register', userInfo, config);
        return response.data;
    } catch(err) {
        return rejectWithValue(err.response.data);
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createUser.pending, (state) => {
      })
      .addCase(createUser.fulfilled, (state) => {
      })
      .addCase(createUser.rejected, (state, action) => {
      })
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = userSlice.actions

export default userSlice.reducer;