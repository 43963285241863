import axios from 'axios';
import _ from 'underscore';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../../services/client';

const initialState = {
  new_release: [],
  videos: [],
}

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers(builder) {
    loadContentCases(builder);
    getVideoUrlCases(builder);
  },
});

export const loadAllContent = createAsyncThunk(
    'content/loadAllContent',
    async () => {
        const response = await axios.get(BASE_URL + '/content/all');
        return response.data;
    }
)

const loadContentCases = (builder) =>
  builder
    .addCase(loadAllContent.pending, (state) => {
    })
    .addCase(loadAllContent.fulfilled, (state, action) => {
        state.videos = action.payload.videos;
        state.new_release = action.payload.new_release;
    })
    .addCase(loadAllContent.rejected, (state) => {
    });

export const getVideoUrl = createAsyncThunk("content/getVideoUrl", async (videoId, { rejectWithValue } ) => {
    try {
      const response = await axios.get(BASE_URL + `/content/url/${videoId}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  });
  
const getVideoUrlCases = (builder) =>
  builder
    .addCase(getVideoUrl.pending, (state) => {
    })
    .addCase(getVideoUrl.fulfilled, (state, action) => {
    })
    .addCase(getVideoUrl.rejected, (state, action) => {
    });

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = contentSlice.actions
export const getVideoByGuid = (state, vidGuid) => _.find(state.content.videos, (v) => v.vid_guid == vidGuid);

export default contentSlice.reducer