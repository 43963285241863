import { alpha } from '@mui/material/styles';

export const alphaBackgroundColor = theme => alpha(theme.palette.background.paper, .90);
export const alphaBackgroundColor75 = theme => alpha(theme.palette.background.paper, .75);
export const alphaBackgroundColor50 = theme => alpha(theme.palette.background.paper, .5);
export const alphaBackgroundColor30 = theme => alpha(theme.palette.background.paper, .3);
export const alphaBackgroundColor25 = theme => alpha(theme.palette.background.paper, .25);
export const alphaBackgroundColor10 = theme => alpha(theme.palette.background.paper, .1);

export const alphaSpaceGray = alpha('#707070', .9)

export const liveAlphaRed = alpha('#d32f2f', .8);

export const lighterBlue = '#3378af' // note: lightblue[900] lighter shade
export const darkerBlue = '#01579b'  // note: lightblue[900] main

export const logoRed = '#f0231c';
export const logoGrey = '#282828'